/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import classes from './vip.module.css'
import { formatDateHeader } from '../../helpers/formatDateHeader'
import { format } from 'date-fns'
import { timeFormatter } from '../../helpers/timeFormatter'
import { FiCopy } from 'react-icons/fi'
import { FaKey, FaLock, FaTags } from 'react-icons/fa6'
import { Loader } from '../../components/loader/loader'
import { bookiesDestination, directPlay } from '../../constant/bookies'
import { show } from '../../constant/toastOptions'
import Convert from '../Convert/Convert'
import UserContext from '../../context/User/userContext'


export default function Vip() {

    const { hasOverlay, tickets, gettingTickets, getVipTickets } = useContext(UserContext)


    const handleCopy = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            show('Code copied to clipboard!', 'success');
        }).catch(err => {
            console.error('Failed to copy code: ', err);
        });
    };

    const placeBet = (bookie, code) => {
        const link = bookiesDestination(bookie, code)
        window.open(link, '_blank');
    }

    return (
        <div className={hasOverlay ? classes.containerOverlay : classes.container}>
            <p className={classes.header}>VIP Games & Convert</p>

            <div style={hasOverlay ? {} : { display: 'none' }} className={classes.modal}>
                <div className={classes.subModal}>
                    {/* <p className={classes.description}>This is a VIP Section</p> */}
                    <div className={classes.subModal2}>

                        <div className={classes.lockContainer}>
                            <FaLock className={classes.lock} />
                        </div>

                        <p className={classes.firstQuestion}>Want to see VIP games?</p>

                        <p className={classes.explanation}>Find out the best of the best games also get the opportunity to use a bet converter.</p>


                        <p className={classes.explanation2}>
                            <FaTags />
                            You unlocked our best available discount.
                        </p>


                        {
                            gettingTickets ?
                                <div style={{ alignSelf: 'center' }} className={classes.explanation2}>
                                    <Loader />
                                </div>
                                :

                                <button onClick={getVipTickets} className={classes.subscribeButton}>Show VIP games
                                    <FaKey />
                                </button>
                        }


                    </div>
                </div>
            </div>

            <div className={classes.vipConvertContainerGrouped}>
                {
                    tickets?.tickets?.length <= 0 ?
                        <div className={classes.vipContainer}>
                            <div className={classes.subContainer}>
                                <Dummy />
                            </div>
                        </div>
                        :
                        <div className={classes.vipContainer}>
                            <div className={classes.subContainer}>
                                {
                                    tickets?.tickets?.map((item, index) => (
                                        <div key={index} className={classes.ticketContainer}>
                                            <div className={classes.topContainer}>
                                                <div className={classes.copyContainer} onClick={() => handleCopy(item.code)}>
                                                    <p className={classes.smallText}>{item.platform}</p>
                                                    <p className={classes.largeBoldText}>{item.code}</p>
                                                    <p className={classes.redText} >Copy code <FiCopy /></p>
                                                </div>
                                                <div className={classes.groupedTotalOddsGameOwner}>
                                                    <p className={classes.largeOdds}>{item.totalOdds}</p>
                                                    <p className={classes.smallText}>{item.countOfGames} game(s) by {item.gameOwner}</p>
                                                </div>
                                            </div>

                                            <div className={classes.groupedGamesContainer}>
                                                {item?.games?.map((game, index) => (
                                                    <div key={index} className={classes.gameContainer}>
                                                        <div>
                                                            <p className={classes.prediction}>{game.prediction}</p>
                                                            <p className={classes.game}>{game.game}</p>
                                                            <p className={classes.timeStamp}>{formatDateHeader(format(new Date(game.start_date), 'yyyy-MM-dd'))} at {timeFormatter(new Date(game.start_date).getTime())}</p>
                                                        </div>
                                                        <p className={classes.gameOdd}>{game.odds}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            {
                                                directPlay.includes(item.platform) &&
                                                <button onClick={() => placeBet(item.platform, item.code)} className={classes.placeBetBtn}>Place Bet</button>
                                            }

                                            {/* <div className={classes.shareBtnContainer}>
                                                <button className={classes.shareBtn}>Convert Code</button>
                                            </div> */}
                                        </div>

                                    ))
                                }

                            </div>
                        </div>
                }

                <div className={classes.convertCodeContainer}>
                    <Convert />
                </div>


            </div>
        </div>
    )
}

function Dummy() {
    return (
        <>
            {
                [...Array(3)].map((_, index) => (
                    <div key={index} style={{ background: '#' }} className={classes.ticketContainer}>
                        <div className={classes.topContainer}>
                            <div className={classes.copyContainer} >
                                <p className={classes.smallText}>Betway</p>
                                <p className={classes.largeBoldText}>******</p>
                                <p className={classes.redText}>Copy code <FiCopy /></p>
                            </div>
                            <div className={classes.groupedTotalOddsGameOwner}>
                                <p className={classes.largeOdds}>*.**</p>
                                <p className={classes.smallText}>****</p>
                            </div>
                        </div>

                        <div className={classes.groupedGamesContainer}>
                            {[
                                {
                                    prediction: '********',
                                    game: '**** vs ****',
                                    start_date: '**** at ***',
                                    odds: '*.**',
                                },
                                {
                                    prediction: '********',
                                    game: '**** vs ****',
                                    start_date: '**** at ***',
                                    odds: '*.**',
                                },
                                {
                                    prediction: '********',
                                    game: '**** vs ****',
                                    start_date: '**** at ***',
                                    odds: '*.**',
                                },
                            ].map((game, index) => (
                                <div key={index} className={classes.gameContainer}>
                                    <div>
                                        <p className={classes.prediction}>{game.prediction}</p>
                                        <p className={classes.game}>{game.game}</p>
                                        <p className={classes.timeStamp}>
                                            {game.start_date}
                                        </p>
                                    </div>
                                    <p className={classes.gameOdd}>{game.odds}</p>
                                </div>
                            ))}
                        </div>

                        <button className={classes.placeBetBtn}>
                            Place Bet
                        </button>

                        {/* <div className={classes.shareBtnContainer}>
                            <button className={classes.shareBtn}>Convert Code</button>
                        </div> */}
                        <button className={classes.shareBtn}>Convert Code</button>

                    </div>
                ))
            }

        </>
    )
}
