import React, { useContext } from 'react'
import classes from './header.module.css'
import logo from '../../assets/images/Best codes logo.png'
import { useNavigate } from 'react-router-dom'
import { loginPath, profilePath, registerPath, subscribePath } from '../../constant/routes'
import man from '../../assets/images/man.png'
import UserContext from '../../context/User/userContext'
import { Loader } from '../loader/loader'
export default function Header() {


    const navigate = useNavigate()
    const { getUser, gettingUser } = useContext(UserContext)

    const goToProfile = async () => {
        await getUser()
        navigate(profilePath)
        console.log(profilePath);
    }
    
    const hasToken = localStorage.getItem('tok')

    return (
        <div className={classes.container}>
            <img onClick={() => navigate('/')} src={logo} alt='' className={classes.logo} />
            {
                !hasToken ?
                    <div className={classes.groupedButtons}>
                        <p onClick={() => { navigate(loginPath) }} className={classes.button}>
                            Sign in
                        </p>

                        <p onClick={() => { navigate(registerPath) }} style={{ background: 'none', color: '#000' }} className={classes.button}>
                            Register
                        </p>
                    </div>
                    :
                    <div className={classes.groupedButtons}>

                        <p onClick={() => { navigate(subscribePath) }} className={classes.button}>
                            Subscribe
                        </p>

                        {
                            gettingUser ?
                                <div className={classes.loaderCon}>
                                    <Loader />
                                </div> :
                                <img onClick={goToProfile} src={man} alt='' className={classes.man} />
                        }


                    </div>
            }
        </div>
    )
}
