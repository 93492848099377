/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import classes from './subscribe.module.css'
import upgrade from '../../assets/images/upgrade.png'
import { pricingPlan } from '../../constant/prices';
import { moneyFormat } from '../../helpers/moneyFormatter';
import { PaystackConsumer } from "react-paystack";
import UserContext from '../../context/User/userContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { show } from '../../constant/toastOptions';
import { loginPath } from '../../constant/routes';
import { Loader } from '../../components/loader/loader';


const url = process.env.REACT_APP_BACKEND_URL
export default function Subscribe() {

    const [value, setValue] = useState(pricingPlan[2]);
    const [isLoading, setIsLoading] = useState(false);
    const { user, getUser } = useContext(UserContext)
    const navigate = useNavigate()

    const token = localStorage.getItem("tok");

    const createAccount = async (reference) => {


        if (!token || !reference || !user) { return };

        const body = JSON.stringify({ reference });

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        setIsLoading(true)

        try {
            await axios.patch(`${url}/account/create`, body, config)
            show('subscription,successful', 'success')

            navigate('/')

        } catch (error) {
            const msg = error?.response?.data?.msg || 'something went wrong'
            show(msg, 'error')

            console.log(msg);

        } finally {
            setIsLoading(false)
        }
    }

    const config = {
        reference: new Date().getTime().toString(),
        email: user?.email,
        amount: parseInt(value?.amount) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
        publicKey: process.env.REACT_APP_PAYSTACK_KEY,
    };

    const handleSuccess = (res) => {

        console.log(res);
        createAccount(res?.trxref);
    };

    const handleClose = () => {
        alert(`error making payment!`);
    };

    const componentProps = {
        ...config,
        text: "Paystack Button Implementation",
        onSuccess: (reference) => handleSuccess(reference),
        onClose: handleClose,
    };


    useEffect(() => {

        if (!token) {
            navigate(loginPath)
            return;
        }
        const fn = async () => {
            setIsLoading(true)
            await getUser()
            setIsLoading(false)
        }
        fn()
    }, [])


    return (
        <div className={classes.container}>

            {
                isLoading ?
                    <div className={classes.loaderContainer}>
                        <Loader />
                    </div>
                    :

                    <>
                        <img src={upgrade} alt='' className={classes.image} />

                        <div className={classes.headerGrouped}>
                            <p className={classes.title}>Upgrade to VIP</p>
                            <p className={classes.subtitle}>Swipe to see more pricing plans</p>
                        </div>

                        <div className={classes.groupedPricing}>
                            {
                                pricingPlan?.map((item, index) => {
                                    const isActive = value.value === item.value
                                    return (
                                        <div onClick={() => { setValue(item) }} style={isActive ? { background: '#F24401' } : {}} className={classes.pricingContainer} key={index}>
                                            <p style={isActive ? { background: '#FFF', color: '#F24401', fontWeight: 'bold' } : {}} className={classes.save}>{item.save}</p>
                                            <p style={isActive ? { color: '#FFF' } : {}} className={classes.duration}>{item.duration}</p>
                                            <p style={isActive ? { color: '#FFF' } : {}}>{item.durationValue}</p>
                                            <p style={isActive ? { color: '#FFF' } : {}}>{moneyFormat.format(item.amount)}</p>
                                        </div>
                                    )
                                })
                            }

                        </div>


                        <PaystackConsumer {...componentProps}>
                            {({ initializePayment }) => (
                                <button onClick={() => initializePayment(handleSuccess, handleClose)} className={classes.button}>
                                    Get {value.duration} {value.durationValue} at {moneyFormat.format(value.amount)}
                                </button>
                            )}
                        </PaystackConsumer>

                        <p className={classes.question}>Does My Subscription Auto-Renew?</p>
                        <p className={classes.answer}>No, your subscription doesn't auto renew. When your subscription expires, yo won't be able to access VIP tickets till you renew your subscription</p>
                    </>
            }


        </div>
    )
}



