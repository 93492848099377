import React from 'react'
import classes from './convert.module.css'

export default function Convert() {
    return (
        <>
            <div className={classes.convertContainer}>
                <div className={classes.inputContainer}>
                    <input placeholder='Enter booking code here' className={classes.input} />
                </div>

                <div className={classes.smallInputContainersGrouped}>
                    <div className={classes.smallInputContainer}>
                        <select className={classes.input}>
                            <option>Convert from</option>
                        </select>
                    </div>

                    <div className={classes.smallInputContainer}>
                        <select className={classes.input}>
                            <option>Convert to</option>
                        </select>
                    </div>
                </div>
            </div>

            <button className={classes.button}>
                Convert
            </button>
        </>
    )
}
