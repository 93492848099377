import React from 'react'
import classes from './sideNav.module.css'
import { LuJoystick } from "react-icons/lu";
import { FaGamepad } from "react-icons/fa";
import { RiAdvertisementFill, RiVipCrown2Fill } from "react-icons/ri";
import { IoNotificationsCircle, IoMailOpenOutline } from "react-icons/io5";
import { RiUserStarFill } from "react-icons/ri";
// import { PiCoinsFill } from "react-icons/pi";
import { addGamePath, addTipstersPath, addVipGamePath, allUsersPath, changeAdPath, sendPushNotificationPath, senMailPath, ticketsPath } from '../../../constant/routes';
import { useNavigate } from 'react-router-dom';
import { FaRectangleList } from 'react-icons/fa6';

export default function SideNav() {

    const navigate = useNavigate()

    const navItems = [
        { name: 'Add Tipsters', route: addTipstersPath, icon: <LuJoystick className={classes.icon} />, },
        { name: 'Add Regular Games', route: addGamePath, icon: <FaGamepad className={classes.icon} />, },
        { name: 'Add VIP Games', route: addVipGamePath, icon: <RiVipCrown2Fill className={classes.icon} />, },
        { name: 'Change Banner', route: changeAdPath, icon: <RiAdvertisementFill className={classes.icon} />, },
        { name: 'Send Push notification', route: sendPushNotificationPath, icon: <IoNotificationsCircle className={classes.icon} />, },
        { name: 'Send Mail', route: senMailPath, icon: <IoMailOpenOutline className={classes.icon} />, },
        { name: 'Tickets', route: ticketsPath, icon: <FaRectangleList className={classes.icon} />, },
        { name: 'Users', route: allUsersPath, icon: <RiUserStarFill className={classes.icon} />, },
        // { name: 'Payment', route: null, icon: <PiCoinsFill className={classes.icon} />, },
    ]

    const path = window.location.pathname

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                {
                    navItems?.map((item, index) => (
                        <div onClick={() => navigate(item.route)} style={item.route === path ? { background: 'rgba(72, 71, 71, 0.522)' } : {}} className={classes.navContainer} key={index}>
                            {item.icon}
                            <p>{item.name}</p>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}
