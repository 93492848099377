export const BOOKIES = {
    SPORTY_BET: 'sporty bet',
    BET_9JA: 'bet 9ja',
    "22_BET": '22bet',
    "1XBET": '1xbet',
}


export const bookiesDestination = (bookie, code) => {
    if (bookie === BOOKIES.SPORTY_BET) return `https://www.sportybet.com/ng/?shareCode=${code}&c=ng`
    if (bookie === BOOKIES.BET_9JA) return `https://sports.bet9ja.com/?bookedCode=${code}`
}

export const bookiesArray = () => {
    try {
        return Object.values(BOOKIES)
    } catch (error) {
        console.log(error);
        return;
    }
}



export const directPlay = [BOOKIES.SPORTY_BET, BOOKIES.BET_9JA]