import React, { useState } from 'react';
import axios from 'axios';
import classes from './game.module.css';
import { show } from '../../../constant/toastOptions';
import { bookiesArray } from '../../../constant/bookies';
import { Loader } from '../../../components/loader/loader';

const url = process.env.REACT_APP_BACKEND_URL;

export default function Regular() {
    const defaultValue = {
        code: '',
        bookie: '',
        ownerName: ''
    };
    const [values, setValues] = useState(defaultValue);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    };

    const handleAddGame = async (e) => {
        e.preventDefault();

        const { code, bookie, ownerName } = values;

        if (!code || !bookie ) {
            show('provide code & bookie!', 'error');
            return;
        }

        const config = {
            headers: {
                authorization: `Bearer ${localStorage.getItem('tok')}`,
                'content-type': 'application/json'
            }
        };

        setIsLoading(true);
        try {
            await axios.post(`${url}/tickets`, JSON.stringify({ code, platform: bookie, gameOwner: ownerName }), config);
            show('Game added successfully!', 'success');
            setValues(defaultValue);
        } catch (error) {
            const errorMessage = error?.response?.data?.msg || 'Something went wrong';
            console.error("Error adding game:", error); // Logging the error for debugging
            show(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.subContainer}>
                <p className={classes.head}>Add Regular Game</p>

                <form onSubmit={handleAddGame}>
                    <div className={classes.inputContainer}>
                        <input
                            placeholder="Enter Code"
                            type="text"
                            name="code"
                            value={values.code}
                            onChange={handleChange}
                            className={classes.input}
                        />
                    </div>

                    <div className={classes.inputContainer}>
                        <select
                            name="bookie"
                            value={values.bookie}
                            onChange={handleChange}
                            className={classes.input}
                            style={values?.bookie === '' ? { color: '#a9a9a9' } : {}}
                        >
                            <option value={''}>Select bookie</option>
                            {bookiesArray()?.map((item, index) => (
                                <option key={index} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className={classes.inputContainer}>
                        <input
                            placeholder="Enter Game owner's Name"
                            type="text"
                            name="ownerName"
                            value={values.ownerName}
                            onChange={handleChange}
                            className={classes.input}
                        />
                    </div>

                    <button type="submit" className={classes.button} disabled={isLoading}>
                        {isLoading ? <Loader /> : 'Add Game'}
                    </button>
                </form>
            </div>
        </div>
    );
}
